const url = (imageId: string) => {
  return `https://imagedelivery.net/Knvys86jJSy4RAoUACMsVg/${imageId}/public`;
};

export const images = {
  'map-to-camp67': url('caddfaa7-49c7-4828-f4bd-03c498dd2e00'),
  'serenity-and-peace': url('4714f889-f02b-4d2e-c1c1-5b1463933900'),
  'haven-3': url('3fc88b4f-2dee-4037-84d9-0ac9bfe7d000'),
  'premium-tent-1': url('2dfeeaf1-d2b7-4359-c46c-6092b8565500'),
  'tradition-3': url('19651333-f9f8-4bfe-90db-313b12123e00'),
  'haven-5': url('2e1b86ff-358c-474c-208a-2d8f76d7f000'),
  'bharat-2': url('d4132e39-edfb-48ca-a8ab-27d489ec9700'),
  'story-of-kashi': url('25a3f63f-c96b-4697-a056-171d21e83a00'),
  'esteemed-3': url('2e2d83cb-09bd-4d0c-ffd6-01914bb70f00'),
  'snippet-4': url('3487464f-3c87-44a7-5f88-79c5ecdf2e00'),
  'bharat-4': url('cc365695-3035-4aa1-8f59-b18387032700'),
  'esteemed-2': url('6ed2b97c-08b5-4f43-d2e4-664f82508a00'),
  'tradition-4': url('31eb78fb-a584-4eaf-4cd8-bd12776f8300'),
  bharat: url('6121baae-d671-4663-8a54-0ab092642200'),
  'pilgrimage-to-ayodhya': url('6d3d97df-0dc5-4ba7-48a1-7c457db93900'),
  'tradition-1': url('64c39506-77f2-4d6d-e07e-0a781fdc2100'),
  'esteemed-1': url('67150961-79d5-4523-0a98-74f2197fb400'),
  'himalayan-pathways': url('e79f9715-1469-4678-70e1-fddbb1f47b00'),
  'serenity-and-peace-2': url('59e54282-9471-4ca8-c629-3c69d6347c00'),
  'serenity-and-peace-3': url('587929b7-1cd0-4872-1d79-9121ac815800'),
  'serenity-and-peace-4': url('0cc1ba94-7efb-480a-6d58-3491572eda00'),
  'the-story-of-kashi': url('c12f9a33-0b3a-44c6-0613-4cd51da76f00'),
  thumbnail: url('650cd794-8bcb-42e5-52bc-82026dbba900'),
  'tradition-2': url('06ce0ca3-4e4f-4317-1e50-57c175306000'),
  'paytm-logo': url('acc3cbf3-e05c-4758-0236-0175048e0400'),
  'tent-header': url('273eaf5b-f0e1-4671-c3b6-3ec14c8f5900'),
  kashi: url('5b8f095d-d511-4308-484e-c17a52a65f00'),
  'esteemed-4': url('17b7b0ab-24ca-4dd0-5f4a-5dfa266cf800'),
  'bharat-1': url('a216fac9-97ab-40b2-efa8-cca620a66a00'),
  'kashi-large': url('4ffb246a-e096-4946-523f-16af5fc26e00'),
  'snippet-2': url('36596766-0f68-4881-e39f-9b44416f3700'),
  'snippet-5': url('22b12c41-8cc0-4bac-d59a-29cdf8ae7200'),
  'safe-and-secure': url('3693d7e3-693f-411b-4e03-64c906c41200'),
  'safe-and-secure-2': url('7fe4cba6-fab7-41ad-4cf9-8dd214dd2600'),
  'snippet-3': url('46c4fe42-f0d1-4736-16ec-73317bd58100'),
  himalayan: url('5a796739-f510-4029-e2b7-aca021806400'),
  'snippet-1': url('01e8b64f-faf9-4a9a-1701-16fe90c6b000'),
  'collage-header': url('77c3b53b-a541-43de-b8bb-2af1107bdd00'),
  'bharat-3': url('5970fe3a-7632-422f-4948-28a97dbdae00'),
  'esteemed-5': url('2a6035dd-d354-4e37-5c1d-a8eea7d5a600'),
  'reservations-header': url('56176579-5682-4eef-622a-d69e10e87000'),
  'haven-2': url('8e27e509-4065-4357-7e58-faf4e8bddd00'),
  standard: url('211586f2-f187-477a-173b-dd7c54db4000'),
  'pasted-movie': url('4c5f7143-6a16-4dde-a8ed-da928aee9500'),
  mahakumbh: url('7253df1d-cdde-47e3-ecea-f28f24574600'),
  'haven-4': url('5df03f18-0449-4b65-c8ff-c89f5312ae00'),
  'haven-1': url('9504d4f9-7e4e-492e-34f1-2745535aa700'),
  premium: url('9d8b74cb-d0ca-4e72-ea57-603bef4f4400'),
  'neat-and-clean': url('020a913a-a526-41a0-cfdb-ca36f0578f00'),
  'neat-and-clean-2': url('18bdbbb3-4e09-4768-a6a1-53324ec1a400'),
  deluxe: url('3e57616b-a2ef-48af-a8d1-f0d776761100'),
  tower: url('e6c41cea-a57e-4d41-2366-b5739cfea200'),
  tradition: url('e9488a5d-c069-4ef1-5f72-df47b12c1000'),
  'sattvic-and-fresh': url('5cc71383-78b1-48a9-4315-f7052716b100'),
  'stripe-logo': url('da38d274-a11b-4172-e3a5-65800b843200'),
  logo: url('e00e8c8d-aa79-4690-d6c3-32b7d7c98a00'),
  'haven-7': url('a67a6681-08db-4584-0344-4a4770c1c900'),
  'haven-6': url('db0d5512-8093-4b24-fde2-8f0f56ef4e00'),
  'haven-8': url('86711ea7-481f-4bca-268c-4f77efdc0400'),

  // New stuff
  'incin-logo': url('8821daa2-06bf-4581-0984-ff5e4a492a00'),
  ficci: url('f333d855-7567-4cd8-e957-7bdd9bc77800'),
  icc: url('3307b8e9-4602-43f0-e87e-fddd5d8a9b00'),
  ycb: url('98c5ffce-0872-4c8c-b305-4e2807323800'),
  'mot-govin': url('31025c91-2058-4976-fee1-95435109cc00'),
  'mot-medal': url('59c414fb-203a-4f51-cc97-6b127549c800'),
  'ministry-ayush': url('70b44f09-ad9f-40ea-be88-f781a34c4400'),
  iato: url('9930b5bd-b311-4897-0f81-7694fc4d6400'),
  'sanskriti-van-hero-bg': url('a2609867-7df9-41bb-0289-b0bd445f0a00'),
  'gallery-1': url('42982f39-8e3e-4e2e-c163-6173ebde7500'),
  'gallery-2': url('c0ca1c79-5497-4792-cd58-145997b0ea00'),
  'gallery-3': url('0cccdf3a-f019-4c19-9d6e-20d3209c1000'),
  'gallery-4': url('4b5aebef-8be9-4d19-0533-7b863a01e500'),
  'gallery-5': url('ef5e19d6-b23f-4e70-2dbc-5532eb21d700'),
  'gallery-6': url('561f74d3-14f8-4bf0-e8c4-892559bc2900'),
  'gallery-7': url('fd09c1cb-10c7-43db-a462-b4c75d321b00'),
  'gallery-8': url('2cd1c6f1-5ee5-46cd-0858-de73a1906e00'),
  'thatch-hut': url('eda2b178-6f01-49f1-432e-08725580d900'),
  'thatch-hut-2': url('7bfd4e87-25e9-4ef7-9ed4-72646fd02d00'),
  mudhouse: url('7b9b3a17-6163-42b4-3191-fc8363a66d00'),
  'avatar-gabriella': url('b194211e-043f-41c4-53c8-3ea94cc9c300'),
  'avatar-rajesh': url('8699fcd3-8b83-47da-a1ed-e61cce9a2000'),
  'avatar-pawars': url('966e715a-16e2-477d-1d5c-f9f406c99b00'),
  'avatar-richard': url('7ff2bd53-aacc-4472-2091-c5ef8c31d000'),
  'avatar-bobby': url('a7184755-f8d2-425c-047f-7359c4101100'),
  'testimonials-thumbnail': url('c9b4e46d-e6b6-45a5-b2b2-60d64b9b2a00'),
  'mahakumbh-package': url('2392a72e-b36a-4d6a-20e4-7307c8756b00'),
  mandir: url('fbb2564d-913e-455d-044c-11cae4a4d700'),
  amrit: url('95b628c5-1af4-4a6d-bba7-525a4be49c00'),
  'mudhouse-suite': url('1c35449c-0265-4898-eaa3-a06d375c6900'),
  'mudhouse-1': url('7b9b3a17-6163-42b4-3191-fc8363a66d00'),
  'mudhouse-2': url('1816773c-369b-48bf-bef9-2e604c200b00'),
  'mudhouse-3': url('474467a0-bc8c-4788-c3c4-d8869dcae900'),
  'mudhouse-4': url('29895a5b-22e7-490a-59a9-698bec6dfd00'),
  'mudhouse-5': url('cbba2181-d243-4d6c-b2e0-29d96c6a2c00'),
  'mudhouse-6': url('5d6e3a88-3c74-48af-8991-ed35dfeba100'),
  'mudhouse-7': url('6e482006-a1e9-4b2c-52c5-288d32d43300'),
  // camp67
  c67Logo: url('e9f193ff-26ef-4d57-6c8e-169bd5e0e700'),
  c67FamilyPlus: url('514b0457-5aa7-4e12-dfc6-9449fe1f9300'),
  c67Twin: url('f006fb0c-34f1-429a-b112-a1b0a1b70600'),
  c67DormSingles: url('8e9b63f7-5b13-4bb2-294a-5a27a7e15000'),
};
