import React from 'react';
import clsx from 'clsx';
import { onOpenInquiry } from '../state/inquiry-modal';
import { Socials } from './socials';
import { useTranslation } from 'react-i18next';

interface NavigationProps {
  isDark?: boolean;
  shouldShowNav?: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({
  isDark = false,
  shouldShowNav = false,
}) => {
  return <WebNavigation isDark={isDark} shouldShowNav={shouldShowNav} />;
};

const WebNavigation: React.FC<NavigationProps> = ({
  isDark = false,
  shouldShowNav = false,
}) => {
  const [showNav, setShowNav] = React.useState(shouldShowNav);

  const listener = React.useCallback(() => {
    if (!shouldShowNav) {
      setShowNav(window.scrollY >= (isDark ? 0.1 : 0.3) * window.innerHeight);
    }
  }, [isDark, shouldShowNav]);

  React.useEffect(() => {
    listener();
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, [listener]);

  const { t } = useTranslation(['home', 'common']);

  return (
    <nav
      className={clsx(
        'fixed top-0 left-1/2 transform -translate-x-1/2 w-full flex flex-col pt-2 pb-5 sm:pb-9 sm:pt-4 h-[unset] sm:h-[88px] z-10 px-8 sm:px-12 md:px-24 lg:px-64',
        showNav
          ? 'bg-white text-black border-b-2 border-gradient-to-r from-orange-400 to-transparent'
          : 'text-white',
        isDark && 'text-black',
      )}
    >
      <span className="flex flex-row items-center gap-2 flex-wrap pb-3 xs:pb-0">
        <p className="flex flex-row flex-wrap items-center gap-2">
          <strong>For bookings only </strong>
          (
          <img
            src="/whatsapp.png"
            alt="Whatsapp button"
            className="w-6 h-6 shadow-lg rounded-full pointer-events-none select-none"
          />{' '}
          or 📞)
        </p>
        <span className="flex flex-row flex-wrap items-center whitespace-nowrap gap-1">
          <span className="flex flex-row items-center gap-1">+91 8485835675</span>
          {' / '}
          <span className="flex flex-row items-center gap-1">+91 8860092457 </span>
        </span>
      </span>
      <div
        className={clsx(
          'flex flex-wrap gap-2 items-start sm:items-center justify-center xxs:justify-between w-full border-b-2 pb-2',
          {
            'border-b-white/50': !isDark,
          },
        )}
      >
        <div className="flex flex-col items-center xxs:items-start">
          <a
            className="text-lg flex flex-row items-center sm:text-xl font-serif tracking-widest"
            href="/"
          >
            {t('common:ks-logo')}
          </a>
          <a
            className="text-[10px] flex sm:hidden mt-1 font-sans uppercase tracking-widest font-semibold hover:underline transition-all"
            href="/about-us"
          >
            {t('common:about-us.nav')}
          </a>
          <a
            className="text-[10px] flex sm:hidden mt-1 font-sans uppercase tracking-widest font-semibold hover:underline transition-all"
            href="/contact-us"
          >
            {t('common:contact-us.nav')}
          </a>
        </div>
        <ul className="flex flex-row items-center gap-6">
          <span className="flex flex-row items-center gap-3">
            <a
              className="text-xs hidden sm:flex font-sans uppercase tracking-widest font-semibold hover:underline transition-all"
              href="/about-us"
            >
              {t('common:about-us.nav')}
            </a>
            <a
              className="text-xs hidden sm:flex font-sans uppercase tracking-widest font-semibold hover:underline transition-all"
              href="/contact-us"
            >
              {t('common:contact-us.nav')}
            </a>
            <span
              className={clsx('h-6 hidden md:block transition-all w-px', {
                'bg-black/10': showNav,
                'bg-white/70': !showNav,
              })}
            />
            <button
              onClick={() => onOpenInquiry()}
              className="text-black uppercase font-bold text-[12px] sm:text-sm tracking-widest bg-green-100 rounded-sm px-2 sm:px-4 py-2"
            >
              {t('common:book-now')}
            </button>
          </span>
          <div className="hidden md:flex flex-row items-center gap-2">
            <Socials fill={showNav ? 'black' : 'white'} />
          </div>
        </ul>
      </div>
    </nav>
  );
};
